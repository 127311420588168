import { graphql } from 'gatsby'
import Page from '../containers/Blog'

export default Page

export const query = graphql`
query BlogQuery {
  allFile(filter: {sourceInstanceName: {eq: "blog_images"}}) {
    nodes {
      name
      publicURL
      relativePath
    }
  }
  allWpPost(sort: {fields: date, order: DESC}) {
    nodes {
      categories {
        nodes {
          name
        }
      }
      date(formatString: "YYYY/MM/DD")
      excerpt
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                aspectRatio: 1.33
              )
            }
          }
        }
      }
      content
      slug
      tags {
        nodes {
          name
        }
      }
      title
    }
  }
}
`
