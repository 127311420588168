import React, { Fragment, useMemo } from 'react'
import { Stack } from '@chakra-ui/layout'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../Layout'

import SupportAndReport from '../../components/SupportAndReport'
import Box from '../../components/Box'
import Banner from '../Banner'

import { Media, responsive } from '../../components/ThemeProvider/theme'
import Book from './Book'
import web from './web.yaml'
import mobile from './mobile.yaml'
import useLayers from '../HomePage/useLayers'
import title from '../HomePage/images/sections/blog.svg'
import BackgroundImage from '../../components/BackgroundImage'

const decoLayout = [
  ['bullet-pin', 'pencil'],
  null,
  ['eraser', 'brush'],
]

const Blog = ({ data: { allFile: { nodes: images }, allWpPost: { nodes: articles } } }) => {
  const getLayers = useLayers(images)

  const decos = useMemo(() => {
    const mobileImages = getLayers(mobile, 0)
    const webImages = getLayers(web, 0)
    return decoLayout.map(row => row && row.map((key, i) => {
      const imgIdx = mobile.images.findIndex(img => img.name === key)
      return (
        <Fragment key={i}>
          <Media at="mobile">{mobileImages[imgIdx]}</Media>
          <Media greaterThan="mobile">{webImages[imgIdx]}</Media>
        </Fragment>
      )
    }))
  }, [getLayers])
  return (
    <Layout title="數據狂的筆記" bg="custom.lightBlueBg">
      <Banner
        photo={<StaticImage src="banner_mobile.png" layout="fullWidth" />}
        photoWeb={<StaticImage src="banner_web.png" layout="fullWidth" />}
        title={<BackgroundImage ratio={501 / 103} src={title} />}
        text={"數據狂每天都看很多數據\n記錄下特別的、怪奇的、好玩的大小數\n也詳細記載製作圖表的工具和技巧，來偷窺吧！"}
        textWidth="48%"
        logoRight={responsive('-3em', 0)}
        reverse
      />
      <Box pt={responsive('2rem', '3.875em')}>
        <Stack spacing={responsive('5rem', '6rem')} pt={responsive('2rem', '5rem')}>
          {articles.map((data, i) => (
            <Book
              data={data}
              side={i % 2}
              decos={decos[i]}
              key={i}
            />
          ))}
        </Stack>
        <SupportAndReport />
      </Box>
    </Layout>
  )
}

export default Blog
