import { Flex, Stack } from '@chakra-ui/layout'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

import { responsive } from '../../components/ThemeProvider/theme'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Link from '../../components/Link'
import BackgroundImage from '../../components/BackgroundImage'
import useHtmlText from '../../utils/useHtmlText'

import notebook from './book.svg'
// import notebookMemo from './notebook-memo.svg'

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

const ReadMore = ({ to, ...props }) => {
  return (
    <ResponsiveEllipsis
      component={Text.R}
      basedOn="letters"
      ellipsis={
        <Link ml="0.5em" textDecoration="underline" fontWeight="bold" to={to}>
          ... [閱讀更多]
        </Link>
      }
      whiteSpace="normal"
      fontWeight="400"
      lineHeight="1.75"
      {...props}
    />
  )
}

const Book = ({ data, side, decos, ...props }) => {
  // const exerpt = useHtmlText(data.excerpt)
  const content = useHtmlText(data.content)
  const to = `/blog/${data.slug}/`
  const pSide = side ? 'pr' : 'pl'
  return (
    <Box.Relative {...props}>
      <Flex
        flexDirection={side ? 'row-reverse' : 'row'}
        maxWidth="1300px"
        mx="auto"
        px={responsive(0, '1em')}
      >
        <Box.Relative flex="9">
          <Box
            width={responsive('157%', '100%')}
            transform={responsive(
              `translateX(${side ? '-3%' : '-33%'})`,
              'none'
            )}
          >
            <BackgroundImage
              src={notebook}
              ratio={679 / 540}
              transform={side && 'scale(-1, 1)'}
            />
            <Box.Absolute
              left={responsive(side ? '15%' : 'auto', '15%')}
              right={responsive(side ? 'auto' : '15%', 'auto')}
              top="0.125em"
              bg="black"
              px="1em"
              py={responsive('0.5em', '1em')}
              color="white"
              borderTopRightRadius="1em"
              borderTopLeftRadius="1em"
              transform="translateY(-100%)"
            >
              <Text fontWeight="500" fontSize={responsive('0.875em', '1em')}>
                {data.categories?.nodes[0]?.name}
              </Text>
            </Box.Absolute>
            <Box.Absolute
              left={side ? '7.5%' : '3.5%'}
              right={side ? '3.5%' : '7.5%'}
              top="4%"
              bottom="13.5%"
            >
              <Stack
                borderRadius={responsive('5vw', '2.5vw', '2em')}
                overflow="hidden"
                transform="translate3d(0,0,0)"
                border="0.25em solid black"
                bg="white"
              >
                <Link to={to}>
                  <Box
                    {...{
                      [pSide]: responsive('30%', 0),
                    }}
                    pb={responsive('24%', 0)}
                    bg="black"
                  >
                    <GatsbyImage
                      image={getImage(data.featuredImage?.node.localFile)}
                      alt={data.title}
                    />
                  </Box>
                  <Box.Absolute
                    color="white"
                    bottom="0"
                    backgroundImage="linear-gradient(transparent, black, black)"
                    pt="20%"
                    pl={responsive(side ? '1em' : '35%', '1em')}
                    pr={responsive(side ? '35%' : '1em', '1em')}
                    pb="1em"
                    as={Stack}
                    spacing={responsive('0.25em', '0.5em')}
                  >
                    <Text.P
                      as="h2"
                      lineHeight="1.375"
                      fontSize={responsive('1.125em', '1.5em')}
                    >
                      {data.title}
                    </Text.P>
                    <ReadMore
                      to={to}
                      text={content.slice(0, 200)}
                      maxLine={3}
                    />
                  </Box.Absolute>
                </Link>
              </Stack>
            </Box.Absolute>
          </Box>
        </Box.Relative>
        <Box flex={responsive(1, 4)} />
      </Flex>
      {decos}
    </Box.Relative>
  )
}

export default Book
