import { useMemo } from 'react'

const useHtmlText = (html, glue = '\n') => {
  return useMemo(() => {
    if (typeof DOMParser === 'undefined' || !html) return ''
    const body = new DOMParser()
      .parseFromString(html, 'text/html').body
    return Array.from(body.childNodes).reduce((all, ele) => {
      if (ele.nodeName !== 'P') return all
      const txt = ele.textContent.trim()
      if (txt) all.push(txt)
      return all
    }, []).join(glue)
  }, [html])
}

export default useHtmlText
